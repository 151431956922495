@font-face {
  font-family: Inter-Black;
  src: url(../fonts/inter/Inter-Black.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/inter/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(../fonts/inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(../fonts/inter/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(../fonts/inter/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/inter/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/inter/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(../fonts/inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Thin;
  src: url(../fonts/inter/Inter-Thin.ttf);
}

/* Body */

a:hover {
  text-decoration: none;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: Inter-Regular;
}

html,
body,
#app,
#root,
#app > div,
.App,
.view {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  font-family: Inter-Regular;
  background-color: #000;
  /* background-color: #fdfcff; */
}

/* Navigation bar */

/* Desktop */

#navigation-bar-container-outter-view {
  background-color: black;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  z-index: 20000;
}

#menu-spacer {
  margin-top: 52px;
}

.logo-column {
  min-width: 150px;
}

.logo {
  width: 150px;
}

.nav-bar-button {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: white;
}

.nav-bar-button:hover {
  color: white;
}

.client-button {
  background: #ffffff;
  border-radius: 100px;
  height: 52px;
  width: 120px;
}

.client-button a {
  font-family: Inter-Regular;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.client-button a:hover {
  color: #000000;
}

.navigation-actions-desktop {
  display: inline-block;
}

.navigation-actions-mobile {
  display: none;
}

#desktop-navigation-bar {
  display: inline-block;
}

.full-height {
  height: 100% !important;
}

#mobile-menu-links-container {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
}

.mobile-button-action-container {
  margin-top: 40px;
}

.black {
  background-color: black;
  color: white;
}

.mobile-button-action-text {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #ffffff;
  text-decoration: none;
}

.mobile-button-action-text:hover {
  color: #ffffff;
}

.client-loging-mobile-container {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

/* Mobile */

@media (max-width: 990px) {
  /* Hide the desktop actions */
  .navigation-actions-desktop {
    display: none;
  }

  #desktop-navigation-bar {
    display: none;
  }

  .navigation-actions-mobile {
    display: inline-block;
  }

  .logo-column {
    min-width: 150px;
  }

  .logo {
    width: 150px;
    height: 51px;
  }
}

/* Main page header  */

.main-page-header-image {
  width: 100%;
}

#home {
  background-color: #fff !important;
}

.home-page-header-asset-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.designer-keyword {
  background-color: red;
  display: inline-block;
}

.designer-header-text-image {
  position: absolute;
  margin-top: -52px;
  margin-left: -205px;
}

.client-header-text-image {
  position: absolute;
  margin-top: -52px;
  margin-left: -187px;
}

.client-header-text-image-tablet {
  position: absolute;
  margin-top: 0px;
  margin-left: -185px;
  display: none;
}

.client-header-text-image {
  display: inline-block;
}

@media (max-width: 1200px) {
  .client-header-text-image-tablet {
    display: inline-block;
  }

  .client-header-text-image {
    display: none;
  }
}

.home-page-header-asset-container-mobile {
  display: none;
}

.home-page-header-asset-container {
  display: inline-block;
}

.main-page-header-image-mobile {
  width: 100%;
}

@media (max-width: 991px) {
  .home-page-header-asset-container-mobile {
    display: inline-block;
  }

  .home-page-header-asset-container {
    display: none;
  }
}

/* Mobile views */

.home-page-header-title-container-mobile {
  padding-top: 80px;
}

.designer-header-text-image-mobile {
  position: absolute;
  margin-top: -55px;
  margin-left: -127px;
  width: 250px;
}

.client-header-text-image-mobile {
  position: absolute;
  margin-top: 4px;
  margin-left: -119px;
  width: 140px;
}

.home-page-header-title-container-mobile {
  display: none;
}

.home-page-header-title-container {
  display: block;
}

/* Mobile */

@media (max-width: 990px) {
  .home-page-header-title-container-mobile {
    display: block;
  }

  .home-page-header-title-container {
    display: none;
  }
}

/* Process */

.process {
  background-color: #f6f6f6;
  padding-bottom: 60px;
}

.process-image-container-desktop {
  margin-top: 90px;
  margin-bottom: 140px;
  display: block;
}

.process-image-container-mobile {
  margin-top: 50px;
  margin-bottom: 90px;
  display: none;
}

.process-image {
  width: 100%;
}

@media (max-width: 990px) {
  .process-image-container-mobile {
    display: block;
  }

  .process-image-container-desktop {
    display: none;
  }
}

.process-design {
  width: 100%;
}

.process-see {
  width: 100%;
}

/* Perks  */

.perks-container {
  margin-top: 100px;
}

.trusted-by {
  padding-bottom: 140px;
}

.parkes-bottom-space {
  margin-bottom: 200px;
}

@media (max-width: 990px) {
  .perks-container {
    margin-top: 70px;
  }

  .parkes-bottom-space {
    margin-bottom: -60px;
  }
}

.perk-block {
  margin-top: 40px;
}

.perk-block-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;

  letter-spacing: 0.01em;
}

.perk-block-description {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 140%;

  letter-spacing: -0.03em;
  margin-top: 12px;
}

.tool-image {
  position: relative;
  float: right;
  margin-top: -665px;
}

@media (max-width: 1600px) {
  .tool-image {
    position: relative;
    float: right;
    margin-top: -533px;
    width: 800px;
  }
}

@media (max-width: 1300px) {
  .tool-image {
    position: relative;
    float: right;
    margin-top: -340px;
    width: 590px;
  }
}

.tool-image-mobile {
  position: relative;
  width: 100%;
  margin-top: 58px;
  padding-bottom: 90px;
}

@media (max-width: 770px) {
  .tool-image-mobile {
    position: relative;
    width: 100%;
    margin-top: 58px;
    padding-bottom: 90px;
  }
}

.perks-col {
  max-width: 500px !important;
}

/* Benefits */

.benefits-round-background {
  background-color: black;
  width: 90%;
  border-radius: 0px 100px 100px 0px;
  padding-bottom: 50px;
}

.benefits-container {
  padding-top: 0px;
}

.benefits-left-side {
  padding-top: 60px;
  margin-bottom: 50px;
  margin-left: 7%;
}

/* Mobile view */
@media (max-width: 990px) {
  .benefits-left-side {
    margin-left: 0%;
  }
}

.member-benefits-description {
  margin-top: 32px;
  max-width: 468px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  letter-spacing: -0.03em;

  color: rgba(255, 255, 255, 0.7);
}

.benefit-row {
  padding-top: 50px;
}

.benefit-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;

  letter-spacing: -0.02em;

  color: #ffffff;
}

.benefit-description {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  letter-spacing: -0.03em;

  color: #ffffff;
}

.benefits-rectangle-image {
  position: absolute;
  margin-top: -45px;
  margin-left: -15px;
}

.benefits-rectangle-image-mobile {
  position: absolute;
  margin-top: 27px;
  margin-left: -12px;
}

.benefits-illustration-image {
  position: relative;
  margin-top: -100px;
  float: right;
}

.benefits-illustration-image-mobile {
  position: relative;
  float: right;
  margin-top: -165px;
}

.benefits-block {
  margin-top: 50px;
  width: 780px;
}

@media (max-width: 1200px) {
  .benefit-title {
    font-size: 25px;
  }
}

/* Mobile view */
@media (max-width: 990px) {
  .benefits-round-background {
    width: 100%;
    border-radius: 0px;
    padding-bottom: 0px;
  }

  .benefit-title {
    font-size: 20px;
  }

  .benefit-description {
    font-size: 16px;
  }

  .member-benefits-description {
    font-size: 16px;
  }

  .benefit-row {
    padding-top: 40px;
  }

  .benefits-block {
    margin-top: 30px;
    margin-bottom: 200px;
    width: 100%;
  }

  .benefit-row {
    padding-top: 40px;
    max-width: 320px;
  }

  .benefit-description {
    padding-top: 20px;
  }
}

/* Featured projects */

.featured-projects {
  margin-top: 280px;
}

@media (max-width: 990px) {
  .featured-projects {
    margin-top: 180px;
  }
}

.featured-projects-description {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 160%;

  text-align: center;
  letter-spacing: -0.01em;

  color: #000000;
  padding-top: 31px;
}

.case-study {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
}

.case-study-item {
  display: flex;
  justify-content: center;
}

.case-study-item-mobile {
  display: flex;
  justify-content: center;
}

.case-study-items-mobile {
  margin-bottom: 100px;
  padding-top: 40px;
  max-width: 500px;
}

.case-study-items-mobile .carousel-indicators {
  position: absolute;
  margin-top: 100px;
}

.case-study-items-mobile .carousel-indicators button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  background-color: #000;
}

.case-study-items-mobile .carousel-indicators [data-bs-target] {
  background-color: #000 !important;
}

.case-study-items-mobile .carousel-indicators {
  bottom: -70px !important;
}

.featured-projects-case-studies {
  padding-top: 80px;
  display: flex;
  justify-content: center;
}

.featured-action-container {
  display: flex !important;
  justify-content: center;
  margin-bottom: 20px;
}

/* Services */

.services-container {
  background-color: black;
  background-image: url(../img/bars-background.svg);
  background-repeat: no-repeat;
  min-height: 500px;
  width: 100%;

  padding-top: 100px;
  padding-bottom: 100px;
}

.services-description {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 160%;

  letter-spacing: -0.01em;
  max-width: 500px;
}

.services {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;

  padding-bottom: 20px;
}

.services-image {
  width: 100%;
}

/* Membership  */

.membership-container {
  background-image: url(../img/yellow-background.svg);
  padding-top: 60px;
  padding-bottom: 60px;
}

.most-popular-service-image {
  margin-top: -80px;
  align-items: center;
  margin-left: 30px;
  width: 100%;
}

.membership-options {
  background-color: white;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
  border-radius: 32px;

  min-height: 200px;
}

.membership-title {
  margin-bottom: 60px;
}

.membership-item {
  margin-left: 31px;
  min-height: 600px;
  border-right: 3px solid #f7f7f7;
}

.membership-item-title {
  padding-top: 50px;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.membership-item-title-first {
  padding-top: 27px;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.membership-item-subtitle {
  padding-top: 10px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  min-height: 70px;
  color: #585656;
}

.membership-item-subsubtitle {
  padding-top: 10px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  min-height: 70px;
  color: #585656;
}

.membership-item-whats-included {
  padding-top: 50px;
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.membership-perk {
  padding-top: 20px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  max-width: 215px;
  min-height: 31px;
  color: #585656;
}

.membership-item-price-label {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.membership-item-bottom {
  align-items: center;
  text-align: center;

  margin-top: 100px;
  margin-right: 31px;
}

.membership-free-trial-container {
  float: right;
  margin-right: 60%;
}

.membership-days-free-trial {
  position: absolute;
  margin-top: -70px;
}

.book-a-call-image {
  margin-top: 50px;
  width: 60px;
}

.book-a-call-image-mobile {
  margin-top: 20px;
}

.book-a-call-title {
  padding-top: 30px;

  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  letter-spacing: -0.03em;

  color: #000000;
}

.book-a-call-title-free-trial {
  padding-top: 30px;
  font-size: 30px;
  line-height: 100%;
}

.membership-item-free-trial {
  position: absolute;
  margin-top: -20px;
  color: #5dcc97;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
}

.membership-item-mobile {
  margin-left: 45px;
  min-height: 564px;
}

.membership-item-title-mobile {
  padding-top: 50px;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.membership-item-title-first-mobile {
  padding-top: 15px;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
}

.membership-item-subtitle-mobile {
  padding-top: 10px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  min-height: 51px;
  color: #585656;
}

.membership-item-free-trial-mobile {
  position: absolute;
  margin-top: -10px;
  color: #5dcc97;
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
}

.membership-item-whats-included-mobile {
  padding-top: 50px;
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.membership-perk-mobile {
  padding-top: 20px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  max-width: 215px;
  min-height: 31px;
  color: #585656;
}

.most-popular-service-image-mobile {
  position: relative;
  margin-left: calc(((100% - 300px) / 2) + 30px);
  margin-top: -65px;
  width: 300px;
}

.carousel-indicators {
  position: absolute;
  margin-top: 100px;
}

.carousel-indicators button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  background-color: #fff;
}

.carousel-indicators [data-bs-target] {
  background-color: #fff !important;
}

.carousel-indicators {
  bottom: -70px !important;
}

.call-mobile-row {
  margin-top: 90px !important;
}

.membership-item-call-mobile {
  background-color: #fff;
  padding-top: 30px !important;
  padding-bottom: 50px !important;
  padding-left: 40px !important;
  border-radius: 32px;
}

.membership-item-subtitle-mobile-call {
  padding-top: 10px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  max-width: 355px;
  min-height: 51px;
  color: #585656;
}

.membership-row-mobile {
  padding-left: 20px;
  padding-right: 20px;
}

/* FAQs */

.faq-btn {
  border: none;
  background: transparent;
}

.faq-container {
  background-color: #f6f6f6;
  padding-top: 140px;
  padding-bottom: 140px;
  padding-left: 0px;
  padding-right: 0px;
}

.faq-question-title {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

@media (max-width: 990px) {
  .faq-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .faq-question-title {
    font-size: 18px;
    margin-right: 5px;
  }

  .faq-container {
    padding-bottom: 60px;
  }
}

.faq-question-container {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.08);
}

.faq-question-row {
  width: 100% !important;
}

.faq-icon-container {
  min-width: 24px !important;
  max-width: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;

  margin: 0 !important;
  padding: 0 !important;
  margin-right: -10px !important;
}

.faq-question-description {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
}

/* Footer */

.footer-container {
  background-color: black;
  height: 500px;
  padding-top: 50px;
  padding-bottom: 75px;
}

.footer-stars-image {
  position: relative;
  width: 100%;
  margin-top: -135px;
}

.footer-items {
  margin-top: 80px;
}

.logo-small {
  max-width: 140px;
}

.footer-section {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.footer-link-container {
  height: 25px;
  margin-top: 20px;
}

.footer-icon {
  margin-right: 10px;
  height: 20px;
  margin-bottom: 4px;
}

.footer-link-text {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-decoration: none;
  color: white;
}

.footer-link-href {
  text-decoration: none;
}

.footer-link-href:hover {
  text-decoration: none;
}

.footer-disc {
  width: 230px;
  margin-top: 130px;
  padding-bottom: 30px;
  background-color: black;

  font-family: Inter-Regular;
  font-style: normal;
  font-size: 15px;
}

/* Login  */

.full-screen-item {
  position: absolute;
  width: 100%;
  height: calc(100% - 65px);
}

.login-image {
  width: 90%;
}

.login-fields-container {
  width: 425px;
  margin-left: 30px;
}

@media (max-width: 990px) {
  .login-fields-container {
    margin-left: 0px;
  }
}

.input-field-background {
  display: block;
  height: 64px;
  width: 100%;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 14px;
  margin-bottom: 20px;
}

.error-field {
  border: 2px solid #f36b75;
}

.input-field {
  margin-top: 5px;
  height: 50px;
  width: calc(100% - 44px);
  margin-left: 24px;
  margin-right: 24px;
  border: 0px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: -0.01em;

  color: rgba(0, 0, 0, 0.9);
}

.authentication-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.authentication-super-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 130%;
  letter-spacing: -0.03em;

  color: #ffffff;
}

.authentication-subtitle {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #ffffff;
}

.authentication-line {
  width: 100%;
  /* height: px; */
  border-bottom: 3px solid #e2e2e2;
  margin-bottom: 20px;
}

.authentication-left-column-border {
  border-right: 4px solid #1a1a1a;
}

.input-field:focus {
  outline: none !important;
}

.error-image {
  margin-bottom: 4px;
}

.error-text {
  margin-left: 5px;
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: -0.01em;

  color: #f36b75;
}

.login-disclaimer {
  margin-top: 20px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: -0.01em;

  color: #ffffff;
}

/* Subscription */

#subscribe {
  margin-top: 140px;
}

@media (max-width: 990px) {
  #subscribe {
    margin-top: 50px;
  }
}

.membership-option {
  height: 131px;
  color: black;
  background-color: white;
  border: 2px solid #ffffff;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.membership-option-border {
  border: 4px solid #5dcc97 !important;
}

.membership-selector {
  max-width: 45px !important;
}

.membership-price {
  max-width: 80px !important;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 130%;
  margin: 0px;
  margin-right: 20px;

  letter-spacing: -0.03em;

  color: #000000;
}

.membership-plan-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;

  letter-spacing: -0.03em;

  color: #000000;
}

.membership-plan-subtitle {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  letter-spacing: -0.03em;
  margin-right: 0px;

  color: #000000;
}

@media (max-width: 990px) {
  .membership-plan-subtitle {
    margin-right: -80px !important;
    margin-top: 5px;
  }
}

.subscribe-left-image {
  position: relative;
  margin-top: 40px;
  display: block;
}

.subscribe-right-image {
  position: absolute;
  display: block;
  margin-left: 80%;
  margin-top: -100px;
  z-index: 30000;
}

.subscribe-container {
  margin-top: 80px !important;
}

@media (max-width: 990px) {
  .subscribe-container {
    margin-top: -50px !important;
  }
}

@media (max-width: 1200px) {
  .subscribe-right-image {
    display: none !important;
  }

  .subscribe-left-image {
    display: none !important;
  }
}

/* Dashboard  */

.dashboard-container {
  margin-top: 100px;
}

.dashboard-row {
  margin-bottom: 20px;
}

.dashboard-page {
  background-color: #000;
}

.dashboard-profile-element {
  height: 234px;

  background: #fff;
  border-radius: 20px;
  color: #000;
}

.dashboard-task-element {
  height: 234px;

  background: #3a70f6;
  border-radius: 20px;
  color: #000;
}

.dashboard-billing-element {
  background: #fff;
  border-radius: 20px;
  color: #000;
}

.dashboard-profile-container {
  padding-left: 20px !important;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.dashboard-name {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  letter-spacing: -0.01em;
}

.dashboard-card-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: #000;
}

.dashboard-card-task-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: #fff;
}

.dashboard-task-process {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;

  color: #fff;

  margin-top: 26px;
  margin-left: 20px;
}

.dashboard-email {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: rgba(0, 0, 0, 0.75);
  margin-top: 5px !important;
}

.dashboard-info-container {
  display: inline-block;
}

.dashboard-user-image {
  width: 60px;
  height: 60px;
}

.dashboard-user-image-container {
  float: left;
}

.dashboard-user-details {
  padding-top: 5px;
  padding-left: 20px !important;
}

.dashboard-user-right-corner-image {
  position: relative;
  margin-left: 78%;
  margin-top: -60px;
}

@media (max-width: 990px) {
  .dashboard-user-right-corner-image {
    opacity: 0;
  }
}

.dashboard-member {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  text-align: eft;
  letter-spacing: -0.01em;
}

.dashboard-small-text {
  font-family: Inter-Light;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 130%;
  text-align: start;

  letter-spacing: -0.01em;
  margin-top: 5px;
}

.dasboard-info-right-action {
  padding-top: 25px;
  padding-right: 20px;
}

.dasboard-second-row {
  padding-top: 20px;
  color: #000;
}

.dasboard-task-second-row {
  padding-top: 70px;
}

.dashboard-arrow-button {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* or 23px */

  text-align: right;
  letter-spacing: -0.01em;

  color: #3a70f6;
  background-color: transparent;
  border: 0px;
}

.dashboard-title-container {
  padding-left: 20px;
  padding-top: 15px;
}

.dashboard-separator {
  height: 0px;
  border: 1px solid #f6f6f6;
}

.dashboard-plain-button {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 23px */

  text-align: left;
  letter-spacing: -0.01em;

  color: #3a70f6;
  background-color: transparent;
  border: 0px;
  padding-left: 0px !important;
}

.dashboard-billing-third-row {
  padding-top: 90px;
}

.go-to-tasks-button {
  margin-top: 40px !important;
  margin-left: 20px !important;
}

.subscription-status {
  border-radius: 32px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  min-width: 100px !important;
  height: 32px !important;
  text-align: center;
}

.status-text {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.inactive-subscription {
  background: #ffe9e9;
  color: #ed4c57;
}

.active-subscription {
  background: #dafbe8;
  color: #69cd92;
}

.paused-subscription {
  background: #fff3e1;
  color: #ffb341;
}

.small-option {
  height: 100px;
  border: 2px solid #eaeaea;
  margin-bottom: 10px;
}

@media (max-width: 1050px) {
  .small-option {
    height: 130px;
  }
}

.dashboard-membership-selector {
  padding-top: 30px;
}

.dashboard-start-membership {
  margin-top: 0px;
  float: right;
}

.dashboard-membership-container {
  height: 385px;
  margin-left: 20px;
  padding-right: 60px !important;
}

.dashboard-membership-type {
  margin-top: 30px;
}

.dashboard-subscription-type {
  font-family: Inter-Bold;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 130%;
  text-transform: capitalize;
  /* identical to box height, or 42px */

  letter-spacing: -0.03em;
}

.dashboard-subscription-price {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  /* identical to box height, or 42px */

  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
}

@media (max-width: 990px) {
  .dashboard-subscription-type {
    font-size: 24px;
  }

  .dashboard-subscription-price {
    font-size: 24px;
  }
}

.dashboard-whats-included {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: -0.01em;
}

.dashboard-membership-text {
  margin-left: 10px;
  font-family: Inter-Light;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-top: 5px;
  /* or 18px */

  letter-spacing: -0.01em;
}

.dashboard-cancel-subscription-container {
  margin-top: 15px;
}

.dashboard-error-row {
  background: #f36b75;
  border-radius: 14px;
  height: 50px;

  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 20px;
}

.dashboard-error-text {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: -0.01em;

  color: #ffffff;
}

.dashboard-error-container {
  margin-top: 6px;
  margin-left: 10px;
}

.dashboard-update-payment-button {
  background-color: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;

  text-align: right;
  letter-spacing: -0.01em;

  color: #ffffff;
  margin-top: 2px;
}

.dashboard-error-container-items {
  margin-top: 10px !important;
}

.dashboard-margin-top-mobile {
  margin-top: 0px;
}

@media (max-width: 990px) {
  .dashboard-margin-top-mobile {
    margin-top: 20px !important;
  }
}

/* Edit user */

.edit-user {
  background-color: #000;
}

.edit-user-container {
  width: 640px;
  height: 676px;
  background-color: white;
  margin-top: 60px;
  padding: 10px;
  border-radius: 20px;
  color: black;
}

.edit-header-image {
  width: 100%;
}

.edit-title-container {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  /* identical to box height, or 42px */

  letter-spacing: -0.01em;
}

.edit-fields-container {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.logout-button {
  background-color: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #ff6f78;
}

@media (max-width: 990px) {
  .logout-button {
    margin-right: 10px;
  }
}

/* Blog  */

.blog-a {
  text-decoration: none !important;
}

.blog-title {
  margin-top: 100px;
  margin-bottom: 60px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 140%;
  /* or 90px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.blog-post-image {
  margin-top: 40px;
  width: 100%;
}

.blog-post-title {
  margin-top: 20px;
  margin-left: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  /* or 38px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;
}

.blog-post-subtitle {
  margin-top: 10px;

  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */

  letter-spacing: -0.02em;

  color: #000000;
  margin-bottom: 60px;
}

@media (max-width: 990px) {
  .blog-title {
    font-size: 40px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .blog-post-image {
    margin-top: 20px;
  }

  .blog-post-subtitle {
    margin-bottom: 30px;
  }
}

/* Blog post page */

.blog-page-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 150%;
  /* or 81px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;

  margin-top: 70px;
  margin-bottom: 30px;
}

.blog-page-post-image {
  margin-top: 70px;
  width: 100%;
}

@media (max-width: 990px) {
  .blog-page-post-image {
    margin-top: 40px;
  }

  .blog-page-title {
    font-size: 30px;
    margin-top: 40px;
  }
}

.blog-page-reading-time {
  margin-bottom: 60px;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: #4f4c4c;
}

.blog-page-text p {
  /* background-color: red; */
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
}

.blog-page-text h2 {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 150%;
  margin-top: 80px;
  margin-bottom: 40px;
}

.blog-page-text li {
  font-family: Inter-Light;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  margin-top: 30px;
}

.blog-page-text b {
  font-family: Inter-Regular;
}

.blog-page-read-more-articles {
  padding-top: 100px;
  text-align-last: left;

  font-family: Inter-Light;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  margin-top: 30px;
}

@media (max-width: 990px) {
  .blog-page-text h2 {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .blog-page-read-more-articles {
    padding-top: 70px;
  }
}

.blog-free-trial-desktop {
  width: 100%;
  margin-top: 60px;
}

/* Others */

#video {
  width: 1px;
  height: 1px;
  /* display: none; */
}

.bold-text {
  font-family: Inter-Bold;
  font-style: bold;
  font-weight: 500;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin-left: calc(50% - 29px);
  z-index: 1000;
}

.disabled {
  opacity: 0.5;
}

.no-margin {
  margin: 0px !important;
}

.simple-button {
  background-color: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;

  letter-spacing: -0.01em;
  color: #618dfa;

  margin: 0px;
  padding: 0px;
}

.no-border {
  border: 0px;
}

.center {
  text-align: center;
}

.white-text {
  color: white;
}

.desktop {
  display: block;
}

.white {
  background-color: white;
  color: black;
}

.mobile {
  display: none !important;
}

@media (max-width: 990px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .authentication-left-column-border {
    border-right: 0px;
  }
}

.full-width {
  width: 100%;
}

.d-title {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 70px;

  letter-spacing: 0.01em;
}

.subtitle {
  font-family: Inter-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;

  letter-spacing: -0.03em;
}

@media (max-width: 990px) {
  .title {
    font-size: 35px;
    line-height: 55px;
  }

  .subtitle {
    font-size: 20px;
  }
}

.section {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 57px;

  letter-spacing: -0.03em;
}

.yellow-button {
  background: #ffed29;
  border-radius: 100px;
  color: black;
  height: 52px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 50px;
  padding-right: 50px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.02em;

  color: #000000;
  /* display: inline-block; */
  position: relative;
  z-index: 10000;

  /* Optional */
  text-align: center;
}

.yellow-button-background {
  background: #cab920;
  height: 52px;
  position: relative;
  border-radius: 100px;

  width: 100%;
  left: -7px;
  top: -45px;
  z-index: 0;
}

.yellow-button-container {
  display: inline-block;
}

.yellow-button-container-full {
  display: inline-block;
  width: 100%;
  height: 70px;
}

.yellow-button-button {
  width: 100%;
  height: 200%;
  margin-top: -10px;
  background: transparent;
  border: 0px;
}

.yellow-button-button-small {
  width: 100%;
  height: 200%;
  margin-top: -10px;
  background: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: #000;
}

.white-button-button-small {
  width: 100%;
  height: 200%;
  margin-top: -10px;
  background: transparent;
  border: 0px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;

  letter-spacing: -0.01em;

  color: #000;
}

.yellow-button a {
  text-decoration: none;
  color: #000000;
}

.yellow-button a:hover {
  text-decoration: none;
  color: #000000;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-32 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mr-20 {
  margin-right: 20px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.red {
  background-color: red;
}

.yellow-button-small {
  background: #ffed29;
  border-radius: 100px;
  color: black;
  height: 45px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;

  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: -0.02em;

  color: #000000;
  /* display: inline-block; */
  position: relative;
  z-index: 10000;
}

@media (max-width: 1200px) {
  .yellow-button-small {
    font-size: 17px;
  }
}

.yellow-button-background-small {
  background: #cab920;
  height: 47px;
  position: relative;
  border-radius: 100px;

  width: 100%;
  left: -6px;
  top: -40px;
  z-index: 0;
}

.white-button-background-small {
  background: #fff;
  height: 47px;
  position: relative;
  border-radius: 100px;

  width: 100%;
  left: -6px;
  top: -40px;
  z-index: 0;
}

.yellow-button-container-small {
  display: inline-block;
}

.white-button-container-small {
  display: inline-block;
}

.white-button-small a {
  text-decoration: none;
  color: #000000;
}

.white-button-small a:hover {
  text-decoration: none;
  color: #000000;
}

.yellow-button-small a {
  text-decoration: none;
  color: #000000;
}

.yellow-button-small a:hover {
  text-decoration: none;
  color: #000000;
}

.bordered-button-container {
  border-radius: 100px;
  border: 2px solid #000;
  height: 42px;
  width: 129px;
}

.bordered-button {
  margin-top: 6px;
  align-items: center;
  text-align: center;
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.bordered-button a {
  text-decoration: none;
  color: #000000;
}

.bordered-button a:hover {
  text-decoration: none;
  color: #000000;
}

.hide {
  display: none !important;
}

.black-text {
  color: #000;
  text-align: start;
}

.blue {
  color: #3a70f6;
}

.invisible-button {
  background: transparent;
  border: 0px;
  padding: 0;
  margin: 0;
}

.footer-bold {
  font-family: Inter-SemiBold;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

/* Cookie consent */

body {
  --cc-bg: #f9faff;
  --cc-text: #112954;
  --cc-btn-primary-bg: #3a70f6;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #dfe7f9;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #c6d1ea;
  --cc-toggle-bg-off: #8fa8d6;
  --cc-toggle-bg-on: #3a70f6;
  --cc-toggle-bg-readonly: #cbd8f1;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #ebeff9;
  --cc-cookie-category-block-bg-hover: #dbe5f9;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e1e7f3;
  --cc-overlay-bg: rgba(230, 235, 255, 0.85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: #3859d0;
}

.theme_funky #c-ttl {
  color: var(--cc-btn-primary-bg);
}

/* Custom border radius */
.theme_funky #cm,
.theme_funky #s-bl .act .b-acc,
.theme_funky #s-inr,
.theme_funky .cc_div .b-tl,
.theme_funky .cc_div .c-bl {
  border-radius: 1.2em;
}

.theme_funky .cc_div .c-bn {
  border-radius: 0.7em;
}
